import axios from 'axios';
import { createBrowserHistory } from 'history';
import { default as qs, default as QueryString } from 'qs';
import {
  deleteLocalData,
  getAccessToken,
  getRefreshToken,
  removeAxiosHeaders,
  removeEmpty,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken,
  setRetailAccessToken,
  setRetailRefreshToken,
} from '../utils/helper';
import apiEndpoints from './apiEndPoints';

const history = createBrowserHistory({});
let isTokenrefreshing = false,
  retryReqestArray: any = [];

function onTokenRefresh(authorisationToken: any) {
  retryReqestArray.map((cb: any) => cb(authorisationToken));
}

axios.interceptors.response.use(
  (response) => {
    // window.console.log(response.status);
    return response;
  },
  (error) => {
    // window.console.log(error.response);
    if (error && error.response) {
      if (error.response.status === 401) {
        if (getAccessToken()) {
          if (getRefreshToken()) {
            if (!isTokenrefreshing) {
              removeAxiosHeaders();
              isTokenrefreshing = true;
              const params = {
                grant_type: 'refresh_token',
                refresh_token: getRefreshToken(),
              };
              axios
                .post(apiEndpoints.refreshToken, params)
                .then((response) => {
                  setRefreshToken(response.data.refresh_token);
                  setRetailRefreshToken(response.data.refresh_token);
                  setAccessToken(response.data.access_token);
                  setRetailAccessToken(response.data.access_token);
                  setAxiosHeaders();
                  onTokenRefresh(response.data.access_token);
                  window.location.reload();
                })
                .catch(() => {
                  removeAxiosHeaders();
                  deleteLocalData('accessToken');
                  deleteLocalData('refreshToken');
                  history.push('/');
                  window.location.reload();
                });
            }
            return new Promise((resolve) => {
              retryReqestArray.push((token: any) => {
                const originalRequest = error.config;
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(axios(originalRequest));
              });
            });
          } else {
            removeAxiosHeaders();
            deleteLocalData('accessToken');
            history.push('/');
            window.location.reload();
          }
        }
      }
      // if (error.response.status === 404) {
      //   history.push('/403');
      //   window.location.reload();

      // }
      // if (error.response.status >= 500) { history.push('/500'); window.location.reload(); }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((request: any) => {
  let requestPlatform = 'web';
  try {
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      requestPlatform = 'm-web';
    }
  }
  request.headers['X-REQUEST-PLATFORM'] = requestPlatform;
  request.headers['X-XSS-Protection'] = '1; mode=block';
  request.headers['X-Frame-Options'] = 'DENY';
  request.headers['X-Content-Type-Options'] = 'nosniff';
  request.headers['Referrer-Policy'] = 'strict-origin-when-cross-origin';
  request.headers['Content-Type'] = 'application/json';
  request.headers['Strict-Transport-Security'] = 'max-age=63072000; includeSubDomains; preload';
  return request;
});

const api = {
  profile: {
    login: (credentials: any) => axios.post(apiEndpoints.authenticate, credentials).then((res) => res.data),
    logout: () => axios.post(apiEndpoints.logout).then((res) => res.data),
    googleLogin: (credentials: any) => axios.post(apiEndpoints.googleLogin, credentials).then((res) => res.data),
    microsoftLogin: (credentials: any) => axios.post(apiEndpoints.microsoftLogin, credentials).then((res) => res.data),
    ssoLogin: (params?: any) => axios.get(apiEndpoints.ssoInfo, { params: params }).then((res) => res.data),
    firstUserData: (token: string, cancelToken?: any) =>
      axios
        .get(apiEndpoints.userData, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((res) => res.data),
    userData: (cancelToken?: any) =>
      axios.get(apiEndpoints.userData, { cancelToken: cancelToken }).then((res) => res.data),
    requestOTP: (data: object) => axios.post(apiEndpoints.requestOTP, data).then((res) => res.data),
    phoneFromIDBP: (params: object) =>
      axios.get(apiEndpoints.phoneFromIDBP, { params: params }).then((res) => res.data),
    requestOTPBP: (data: object) => axios.post(apiEndpoints.requestOTPBP, data).then((res) => res.data),
    loginWithOTP: (data: object) => axios.post(apiEndpoints.loginWithOTP, data).then((res) => res.data),
    loginWithOTPBP: (data: object) => axios.post(apiEndpoints.loginWithOTPBP, data).then((res) => res.data),
    forgotPassword: (data: object) => axios.post(apiEndpoints.forgotPassword, data).then((res) => res.data),
    resetPassword: (data: object) => axios.post(apiEndpoints.resetPassword, data).then((res) => res.data),
    changePassword: (data: object, cancelToken: any) =>
      axios.post(apiEndpoints.changePassword, (data = data), { cancelToken: cancelToken }).then((res) => res.data),
    policyStats: (params: object) => axios.get(apiEndpoints.policyStats, { params: params }).then((res) => res.data),
    modifyProfile: (data: object) => axios.post(apiEndpoints.modifyProfile, data).then((res) => res.data),
    getOrgDetails: (id: any, cancelToken?: any) =>
      axios.get(apiEndpoints.orgDetails + id, { cancelToken: cancelToken }).then((res) => res.data),
    checkSSO: (params: object) => axios.get(apiEndpoints.checkSSO, { params: params }).then((res) => res.data),
    getModificationTracking: (params: object) =>
      axios.get(apiEndpoints.modificationTracker, { params: params }).then((res) => res.data),
    cancelEndorsement: (data: object) => axios.post(apiEndpoints.cancelEndorsement, data).then((res) => res.data),
  },
  members: {
    getMember: (params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.membersV2, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    getSingleMember: (id: any, params: object) =>
      axios.get(apiEndpoints.members + '/' + id, { params: params }).then((res) => res.data),
    updateMember: (id: any, data: any) =>
      axios.put(apiEndpoints.members + '/' + id, (data = data)).then((res) => res.data),
    createMember: (data: object) => axios.post(apiEndpoints.members, data).then((res) => res.data),
    createMemberV1: (data: object) => axios.post(apiEndpoints.membersV1, data).then((res) => res.data),
    updateMemberV1: (data: any) => axios.put(apiEndpoints.membersV1, (data = data)).then((res) => res.data),
    addProducts: (data: object) => axios.post(apiEndpoints.addProducts, data).then((res) => res.data),
    removeMember: (data: object) => axios.post(apiEndpoints.memberRemove, data).then((res) => res.data),
    removeDependant: (data: object) => axios.post(apiEndpoints.dependantRemove, data).then((res) => res.data),
    organisationMIS: (data: object) => axios.post(apiEndpoints.organisationMIS, data).then((res) => res.data),
    getUploadTemplate: (params: any, cancelToken?: any) =>
      axios.get(apiEndpoints.uploadTemplate, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    memberDataReview: (data: any) => axios.post(apiEndpoints.memberDataReview, (data = data)).then((res) => res.data),
    memberDataSubmit: (data: any) => axios.post(apiEndpoints.memberDataSubmit, (data = data)).then((res) => res.data),
    memberDataDelete: (data: any) => axios.post(apiEndpoints.memberDataDelete, (data = data)).then((res) => res.data),
    bulkInviteMembers: (data: any) => axios.post(apiEndpoints.bulkInviteMembers, (data = data)).then((res) => res.data),
    getModificationRequests: (params: any) =>
      axios.get(apiEndpoints.getModificationRequests, { params: params }).then((res) => res.data),
    getModificationCount: (params: any) =>
      axios.get(apiEndpoints.getModificationCount, { params: params }).then((res) => res.data),
    inviteMemberDetails: (data: any) =>
      axios.post(apiEndpoints.inviteMemberDetails, (data = data)).then((res) => res.data),
    inviteMemberDetailsV1: (data: any) =>
      axios.post(apiEndpoints.inviteMembersV1, (data = data)).then((res) => res.data),
    invitedMemberDetails: (id: any, data: any) =>
      axios.put(apiEndpoints.inviteMemberDetails + `/${id}`, (data = data)).then((res) => res.data),
    inviteMemberProducts: (data: any) =>
      axios.post(apiEndpoints.inviteMemberProducts, (data = data)).then((res) => res.data),
    getInvitedMemberDetails: (params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.invitedMemberDetails, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    getCoverageType: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.checkCoverageType, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    resendInvite: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.resendInvite, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    updateMemberDetails: (data: any) =>
      axios.post(apiEndpoints.updateSelfDetails, (data = data)).then((res) => res.data),
    getMemberProducts: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.selfProducts, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    updateMemberProducts: (data: any) => axios.post(apiEndpoints.selfProducts, (data = data)).then((res) => res.data),
    hardDelete: (data: any) => axios.post(apiEndpoints.hardDeleteMember, (data = data)).then((res) => res.data),
    hardDeleteEmployeeMember: (data: any) =>
      axios.post(apiEndpoints.hardDeleteMemberEmployee, (data = data)).then((res) => res.data),
    employeeRequestAction: (data: any) =>
      axios.post(apiEndpoints.getModificationRequests, (data = data)).then((res) => res.data),
  },
  products: {
    getProducts: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.products, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    selectChoices: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.selectChoices, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getProductBenefit: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.productBenefit, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getStepsToAvail: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.stepsToAvail, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getCouponCode: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.generateCoupon, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    activatePlan: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.activatePlan, { params: params, cancelToken: cancelToken }).then((res) => res.data),
  },
  claims: {
    getClaims: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.getClaims, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getSingleClaim: (id: any, params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.getClaims + '/' + id, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    claimStats: (params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.claimStats, { params: removeEmpty(params), cancelToken: cancelToken })
        .then((res) => res.data),
    getDocuments: (params: object) =>
      axios.get(apiEndpoints.claimDocuments, { params: params }).then((res) => res.data),
    claimConfig: (data: any) => axios.post(apiEndpoints.claimConfig, (data = data)).then((res) => res.data),
    syncClaims: (data: any) => axios.post(apiEndpoints.claimSync, (data = data)).then((res) => res.data),
  },
  employee: {
    getEmployeeDetails: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.memberDetails, { params: params }).then((res) => res.data),
    getClaims: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.employeeClaims, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getClaimDetails: (id: any, params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.employeeClaims + '/' + id, { params: params }).then((res) => res.data),
    getClaimStats: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.employeeClaimStats, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getProducts: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.employeeProducts, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getEcard: (data: object) => axios.post(apiEndpoints.generateEcard, data).then((res) => res.data),
  },
  UHI: {
    getFamilyMembers: (params: any, cancelToken?: any) =>
      axios.get(apiEndpoints.UHIFamilyListing, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    editFamilyMember: (id: any, data: any) =>
      axios.patch(apiEndpoints.UHIFamilyListing + `/${id}`, (data = data)).then((res) => res.data),
    // validations: (data: any) => axios.get(apiEndpoints.UHIValidations, data = data).then((res) => res.data),
    validations: (params: any, cancelToken?: any) =>
      axios
        .get(apiEndpoints.UHIValidations, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    createUpgradeRequest: (data: any) =>
      axios.post(apiEndpoints.UHIFamilyListing, (data = data)).then((res) => res.data),
    UHIPremiumCalulator: (params: any) =>
      axios
        .get(apiEndpoints.UHIPremiumCalculator, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((res) => res.data),
    additionalDetails: (data: object) => axios.post(apiEndpoints.UHIAdditionalDetails, data).then((res) => res.data),
    updateAdditionalDetails: (data: object, id: any) =>
      axios.put(apiEndpoints.UHIAdditionalDetails + '/' + id, data).then((res) => res.data),
    addMember: (data: object) => axios.post(apiEndpoints.UHIAddMember, data).then((res) => res.data),
    getAdditionalDetails: (params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.UHIAdditionalDetails, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    getProposal: (params: any, cancelToken?: any) =>
      axios.get(apiEndpoints.UHIProposal, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    checkProposal: (data: object) =>
      axios.post(apiEndpoints.UHIProposal, QueryString.stringify(data)).then((res) => res.data),
    getPaymentLink: (data: object) => axios.post(apiEndpoints.paymentRequest, data).then((res) => res.data),
    getPaymentStatus: (data: object) =>
      axios.post(apiEndpoints.paymentStatus, QueryString.stringify(data)).then((res) => res.data),
    getPolicyDocs: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.UHIPolicyDocs, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    test: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.UHIPolicyDocs, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    selectChoices: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.UHIFilter, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    applicationDetail: (params: any, cancelToken?: any) =>
      axios
        .get(apiEndpoints.UHIApplicationDetail, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    renewalStatus: (params?: object, cancelToken?: any) =>
      axios.get(apiEndpoints.ftiRenewal, { params: params, cancelToken: cancelToken }).then((res) => res.data),
  },
  broker: {
    getBrokerConfig: (params?: object, cancelToken?: any) =>
      axios.get(apiEndpoints.brokerConfig, { params: params, cancelToken: cancelToken }).then((res) => res.data),
  },
  hrms: {
    getKnitSessionToken: (data?: any, params?: object, cancelToken?: any) =>
      axios.post(apiEndpoints.knitAuth, data, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getKnitAppList: (params?: object, cancelToken?: any) =>
      axios.get(apiEndpoints.knitAppList, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getKnitConfig: (params?: object, cancelToken?: any) =>
      axios.get(apiEndpoints.knitConfig, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    postKnitConfig: (data?: any, params?: object, cancelToken?: any) =>
      axios.post(apiEndpoints.knitConfig, data, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    memberListing: (params?: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.hrmsMemberListing, {
          params: params,
          cancelToken: cancelToken,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((res) => res.data),
    memberEdit: (data?: any, params?: object, cancelToken?: any) =>
      axios
        .put(apiEndpoints.hrmsMemberListing, data, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    patchMembers: (data?: any, params?: object, cancelToken?: any) =>
      axios
        .patch(apiEndpoints.hrmsMemberListing, data, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    updateStatus: (data?: any, params?: object, cancelToken?: any) =>
      axios
        .post(apiEndpoints.hrmsMemberStatus, data, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    putKnitConfig: (data?: any, params?: object, cancelToken?: any) =>
      axios.put(apiEndpoints.knitConfig, data, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    postKnitProductSubscription: (data?: any, params?: object, cancelToken?: any) =>
      axios
        .post(apiEndpoints.hrmsProductSubscription, data, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    syncKnitProductIntegration: (data?: any, params?: object, cancelToken?: any) =>
      axios.post(apiEndpoints.hrmsSync, data, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    hrmsFilterChoices: (data?: any, params?: object, cancelToken?: any) =>
      axios.post(apiEndpoints.hrmsFilters, data, { params: params, cancelToken: cancelToken }).then((res) => res.data),
  },
};

export default api;
